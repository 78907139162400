nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .brand-container {
      .brand {
        img {
          height: 100%;
        }
      }

      .toggle-container {
        display: none;
        
      }
    }
    .links-container {
      .links {
        list-style-type: none;
        display: flex;
        gap: 4rem;
        
        li {
          
          a {
            color: var(--accent-color1);
            text-decoration: none;
          }
          &:last-of-type {
            a {
              // border: 2px solid var(--blue);
              // background-color: var(--blue);
              // border-radius: 5%;
              // padding: 1vh;
              // color: var(--accent-color1);
              color: var(--accent-color1);
            background-color: var(--background);
            font-weight: bold;
            padding: 0.5rem 2rem;
            border-radius: 0.5rem;
            box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
            border: solid 3px transparent;
          //   background-color: #36096d;
            background-image: linear-gradient(
                
                rgba(54, 9, 109, 1),
                rgba(55, 213, 214, 1)),
             
              linear-gradient(101deg, var(--blue), var(--liteblue));
            background-origin: border-box;
            background-clip: content-box, border-box;
            box-shadow: 2px 1000px 1px var(--background) inset;
            transition: 0.5s ease-in-out;
            cursor: pointer;
            &:hover {
              box-shadow: none;
            }
            }
          }
          .search{
           
            color: var(--accent-color1);
            background-color: var(--background);
            font-weight: bold;
            padding: 0.5rem 2rem;
            border-radius: 0.5rem;
            box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
            border: solid 3px transparent;
          //   background-color: #36096d;
            background-image: linear-gradient(
                
                rgba(54, 9, 109, 1),
                rgba(54, 9, 109, 1),),
             
              linear-gradient(101deg, var(--blue), var(--liteblue));
            background-origin: border-box;
            background-clip: content-box, border-box;
            box-shadow: 2px 1000px 1px var(--background) inset;
            transition: 0.5s ease-in-out;
            cursor: pointer;
            
          }
          
        }
      }
      
    }
  }
  
  